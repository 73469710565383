.css-m9glnp-MuiPaper-root-MuiDialog-paper{
    background-color: #52505059 !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.css-1hyl1h2{
    background-color: #52505059 !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-modal{
    max-width: 60%;
    max-height: 700px;
    object-fit: contain;
    z-index: 999;
}


@media (min-width: 1440px) {    
    .image-modal{
        max-width: 75%;
        max-height: 750px;
    }    
}

@media (min-width: 1700px) {    
    .image-modal{
        max-width: 75%;
        max-height: 850px;
    }    
}
