

.image-container {
    overflow: hidden;
}

.image-container img {
    border-radius: 3px;
    cursor: pointer;
    transition: transform 0.3s ease;
    max-height: 780px;
    /* Transición suave */
}

.image-container:hover img {
    filter: opacity(.7);
    transform: scale(1.010);
    /* Ajusta el valor según lo deseado */
}

.image-container:not(:hover) img {
    transform: scale(1);
    /* Volver al tamaño original al sacar el cursor */
}

